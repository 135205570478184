@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('screens.css');
@import url('animation.css');
@import url('https://use.typekit.net/emv3zbo.css');

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

body {
  @apply text-mainBlack-100 antialiased;
}
body {
  font-family: proxima-nova;
  font-size: 14px;
  line-height: 29px;
  font-weight: normal;
  width: 100%;
  background-color: #f7f7f7;
  height: 100%;
  /* max-width: 1440px; */
  color: #06152b;
}
html {
  height: 100%;
}

a {
  @apply hover:text-inherit;
}
::selection {
  @apply bg-mainLightGreen-60;
}
iframe {
  pointer-events: none;
}
.ant-breadcrumb-link .home {
  color: #37935c;
}
.ant-breadcrumb a {
  @apply text-mainBlack-60;
  transition: color 0.3s;
}
.ant-breadcrumb-link a:hover {
  color: #37935c;
}
.ant-menu-submenu-title {
  padding: 0 !important;
}
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.ant-checkbox-group-item {
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #93d50a;
}
input[type='checkbox']:checked {
  background-color: #93d50a;
}
input[type='checkbox'] {
  color: #93d50a;
}

.ant-pagination-prev {
  @apply ml-auto;
}
.ant-pagination-options {
  @apply hidden;
}
.ant-pagination-prev,
.ant-pagination-next {
  @apply border border-[#C4C4C4] rounded-[10px] h-[50px] shadow-md px-3 shadow-[#0000000D] leading-[50px] lg:w-[129px];
}
.ant-pagination-item {
  @apply bg-[#fafafa] border-0 h-[50px] text-mainOrange-default mr-0 leading-[50px];
}
.ant-pagination-item a,
.ant-pagination-item a:hover {
  @apply text-mainOrange-default;
}
.ant-pagination-item-active a,
.ant-pagination-item-active a:hover {
  @apply bg-mainOrange-default text-white;
}

.ant-pagination-disabled {
  @apply border border-[#C4C4C4] shadow-md opacity-50 shadow-[#0000000D];
}
.ant-pagination-next {
  @apply border-mainOrange-default text-mainOrange-default ml-2.5;
}
.proxima-nova-bold {
  font-family: proxima-nova;
  font-weight: bold;
  font-style: normal;
}

.proxima-nova-regular {
  font-family: proxima-nova;
  font-weight: 400;
  font-style: normal;
}
.proxima-nova-medium {
  font-family: proxima-nova;
  font-weight: 500;
  font-style: normal;
}
.proxima-nova-semibold {
  font-family: proxima-nova;
  font-weight: 600;
  font-style: normal;
}

.largeRadius {
  @apply rounded-2xl;
}
.smallRadius {
  @apply rounded;
}
.greenGradient {
  background: transparent linear-gradient(180deg, #93d50a 0%, #d4ee9d 100%) 0% 0%
    no-repeat padding-box;
}

.mega-menu {
  display: none;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
  height: 552px;
}

.toggleable > label:after {
  content: url('../images/icons/black-arrow-down.svg');
  font-size: 10px;
  padding-left: 11px;
  position: relative;
  top: 5px;
  animation: fade-in 0.3s ease-in both;
}

.toggle-input:checked ~ label:after {
  content: url('../images/icons/black-arrow-up.svg');
  font-size: 10px;
  padding-left: 11px;
  position: relative;
  top: 5px;
  animation: fade-in 0.3s ease-in both;
}
.toggle-input {
  display: none;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.toggle-input:not(:checked) ~ .mega-menu {
  /* /* -webkit-animation: fade-out 0.5s ease-out both; */
  animation: fade-out 0.3s ease-out both;
  /* display: none; */
}
.toggle-input:checked ~ .mega-menu {
  display: block;
}

.hero-section {
  background: url('../images/herobanner.png') no-repeat center center;
  background-size: cover;
  height: 100vh;
  width: 100%;
}
.overlay {
  background: transparent linear-gradient(180deg, #00000000 0%, #00000080 100%) 0% 0%
    no-repeat padding-box;
}

.slide-btn:hover,
.slide-btn:focus {
  background: #93d50a 0% 0% no-repeat padding-box !important;
  color: #fff;
  opacity: 1;
}

.parent {
  width: calc(100% - 1rem);
  border-radius: 15px;
  /* width: 100%; */
  height: 318px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 1rem;
}

.child {
  height: 100%;

  /* height: 100%; */
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.parent .div {
  display: flex;
  flex-direction: column;
  color: white;
  position: relative;
  bottom: 2.5rem;
  left: 1rem;
  /* font-size: 48px; */
  line-height: 58px;
  font-weight: bold;
  position: absolute;
  font-family: proxima-nova;
  padding: 0 1rem;
}

.parent:hover .child {
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.child:before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent
    linear-gradient(180deg, #00000000 0%, #00000095 100%, #0000009a 100%) 0% 0% no-repeat
    padding-box;
}

.tab-active {
  @apply bg-mainOrange-default text-white !important;
}

.swiper-prev-icon {
  background-image: url('../images/icons/black-arrow-down.svg');
}
.swiper-next-icon {
  background-image: url('../images/icons/black-arrow-up.svg');
}
.dropdown-menu::after {
  position: absolute;
  display: block;
  content: '';
  bottom: 100%;
  right: 25px;
  width: 20px;
  height: 20px;
  margin-bottom: -9px;
  background: #ffffff;
  transform: rotate(45deg);
  transition: all 0.4s ease-in-out;
  /* @apply  shadow-lg;  */
}
.hours-checkbox .ant-checkbox-inner {
  @apply rounded-lg h-10 w-10 xl:rounded-2xl xl:h-12 xl:w-12 hover:border-[#047832];
}
.hours-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #047832;
  border-color: #047832;
}
.hours-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  top: 45%;
  left: 27.5%;
  display: table;
  width: 23%;
  height: 50%;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
@import 'tailwindcss/utilities';

/* ==================== User Account ======================= */
:root {
  --main-green: #047832;
  --light-green: #f1f7f4;
  --opaque-green: #0478320d;
  --opaque-green-light: #8ff7b80d;
  --lime: #93d50a;
  --main-orange: #fe8c19;
  --opaque-orange: #fe8c1933;
  --main-gray: #8f8f8f;
  --border-gray: #e0e0e0;
  --light-gray: #f7f7f7;
  --opaque-gray: #8f8f8f0d;
  --main-red: #ea3a3d;
}

.show-lg,
.show-md,
.show-sm {
  display: none;
}

@media (max-width: 767px) {
  .hide-sm {
    display: none !important;
  }
  .show-sm {
    display: block;
  }
  ._sm_box_center {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .hide-md {
    display: none !important;
  }
  .show-md {
    display: block;
  }
}

@media (max-width: 1200px) {
  .hide-lg {
    display: none !important;
  }
  .show-lg {
    display: block;
  }
}

.user-account {
  height: 100vh;
}

.user-account *::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  background-color: transparent;
}

.user-account *::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--main-gray);
}

.bg-accountGreen {
  background-color: var(--main-green) !important;
}

.bg-accountOpaqueGreen {
  background-color: var(--opaque-green) !important;
}

.bg-accountOpaqueGreen-2 {
  background-color: var(--opaque-green-light) !important;
}

.bg-accountLightGreen {
  background-color: var(--light-green) !important;
}

.bg-accountLighterGray {
  background-color: var(--light-gray) !important;
}

.text-11 {
  font-size: 11px;
}

.text-12 {
  font-size: 12px;
}

.text-13 {
  font-size: 13px;
}

.w-auto {
  width: auto;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-15 {
  line-height: 15px;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.big-checkbox {
  width: 30px;
  height: 30px;
}

.green-pill {
  line-height: 22px;
  height: 22px;
  border-radius: 50px;
  color: var(--main-green);
  background-color: var(--opaque-green);
  padding: 0;
  font-size: 11px;
  font-weight: 700;
}

.dd-toggle {
  display: flex;
  padding: 0 15px;
  font-size: 13px;
  border: 1px solid var(--border-gray);
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  height: 30px;
}

.dd-toggle._toggle_green {
  border: none;
  background-color: var(--main-green);
  color: #fff;
}

.dd-toggle img {
  width: 13px;
}

.rotate-180 {
  transform: rotate(180deg);
}

._title_text {
  font-size: 17px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}

._rounded {
  border-radius: 10px;
}

._tag {
  padding: 5px 10px;
  line-height: 15px;
  margin-right: 5px;
  font-weight: 600;
  color: var(--main-orange);
  background-color: var(--opaque-orange);
  font-size: 12px;
  border-radius: 3px;
  word-wrap: nowrap;
  display: inline-block;
}

._text_green {
  color: var(--main-green);
}

.hover-bg-green {
  transition: 200ms;
}

.hover-bg-green:hover {
  background-color: var(--main-green);
  color: #fff;
}

.transition {
  transition: 200ms;
}

._text-white {
  color: #fff;
}

._chip {
  color: #000;
}

.align-center {
  align-items: center;
}

._account_input {
  width: 100%;
}

/* Not checkbox and range */
._account_input input:not(input[type='checkbox']):not(input[type='range']),
._account_input select,
._account_input ._file.__normal {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--border-gray) !important;
  padding: 10px 20px;
  font-size: 14px;
  color: #0c0c0c;
}

._account_input ._file.__normal {
  border: 2px dashed var(--border-gray) !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--opaque-gray);
}

._account_input ._file.__normal img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
}

._account_input ._file.__normal ._label {
  width: calc(100% - 40px);
  padding-left: 10px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: var(--main-gray);
}

._account_input ._file_name {
  font-size: 12px;
  line-height: 18px;
}

._account_input ._file.__full {
  position: relative;
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-gray);
  background-color: var(--light-gray);
  cursor: pointer;
}

._account_input ._file.__full ._file_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition: 200ms;
  cursor: default;
}

._account_input ._file.__full ._overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  padding-top: 5px;
  background: linear-gradient(180deg, #000000cd, transparent);
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
}

._account_input ._file.__full ._label {
  font-size: 13px;
  font-weight: 600;
}

._account_rich_editor ._wrapper {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--border-gray);
  overflow: hidden;
  padding-bottom: 10px;
}

._account_rich_editor ._content {
  padding: 0px 20px;
  color: #0c0c0c;
  height: 200px;
}

._account_rich_editor ._toolbar {
  font-family: 'proxima-nova';
  font-size: 13px;
  color: #0c0c0c;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 10px;
  background-color: var(--light-gray);
}

._account_rich_editor ._toolbar * {
  transition: 200ms;
}

._account_multi_select {
  position: relative;
}

._account_multi_select ._options {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 0;
  border: none;
  border-radius: 10px;
  overflow-y: auto;
  background-color: #fff;
  color: #101010;
  font-size: 14px;
  transition: 200ms;
  box-shadow: 0px 2px 10px #0000003a;
}

._account_multi_select ._options._open {
  display: block;
  max-height: 200px;
}

._account_multi_select ._options ._option {
  padding: 5px 20px;
  cursor: pointer;
  transition: 200ms;
}

._account_multi_select ._options ._option:hover {
  background-color: var(--light-gray);
}

.user-account__body .nav-tabs {
  padding: 0 30px;
}

.user-account__body .nav-tabs .nav-link {
  border: 0;
  border-bottom: 2px solid transparent;
  outline: 0;
  font-weight: 600;
}

.user-account__body .nav-tabs .nav-link.active {
  border-color: var(--main-green);
}

._no_result {
  color: var(--main-gray);
  font-style: italic;
}

.loading-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-screen._overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  backdrop-filter: blur(7px);
  z-index: 9999;
}

.loading-screen__content {
  -webkit-animation: shrink 1.5s linear infinite;
  -moz-animation: shrink 1.5s linear infinite;
  -ms-animation: shrink 1.5s linear infinite;
  -o-animation: shrink 1.5s linear infinite;
  animation: shrink 1.5s linear infinite;
}

@keyframes shrink {
  0% {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -ms-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.loading-screen img {
  -webkit-animation: rotating 1.5s linear infinite;
  -moz-animation: rotating 1.5s linear infinite;
  -ms-animation: rotating 1.5s linear infinite;
  -o-animation: rotating 1.5s linear infinite;
  animation: rotating 1.5s linear infinite;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.alert-container {
  position: fixed;
  bottom: -50vh;
  left: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  width: 280px;
  transition: 500ms;
  z-index: 9999;
}

@media (max-width: 500px) {
  .alert-container {
    width: calc(100% - 40px);
  }
}

.alert-container.open {
  bottom: 20px;
}

.alert-container ._icon {
  width: 30px;
}

.alert-container ._icon img {
  padding: 5px;
  border-radius: 50%;
}

.alert-container ._icon.success img {
  background-color: #f0f9ff;
}

.alert-container ._icon.warning img {
  background-color: #fffbeb;
}

.alert-container ._icon.danger img {
  background-color: #fff1f2;
}

.alert-container ._text {
  width: calc(100% - 60px);
  padding: 5px 10px;
  line-height: 20px;
}

.alert-container ._close {
  float: right;
  cursor: pointer;
}
.alert-container ._close img {
  border-radius: 50%;
  width: 30px;
  padding: 7px;
  transition: 200ms;
}

.alert-container ._close img:hover {
  background-color: #0000000c;
}

.owl_carousel__navigator {
  z-index: 2;
  position: absolute;
  width: calc(100% + 20px);
  margin-left: -10px;
  top: 60%;
  height: 0;
}

.owl_carousel__navigator_icon {
  cursor: pointer;
  background-color: var(--lime);
  padding: 5px;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.owl_carousel .owl-nav {
  display: none;
}

._modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: transparent;
  backdrop-filter: blur(10px);
  z-index: 8888;
  display: none;
  padding: 20px;
  align-items: center;
  justify-content: center;
  transition: 200ms;
}

._modal.open {
  display: flex;
}

._modal_children {
  width: 100vw;
  max-height: 98%;
  padding: 10px 0;
  overflow-y: auto;
  display: flex;
  justify-content: center;
}

._modal__cancel {
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

._modal__cancel img {
  cursor: pointer;
  width: 30px;
}

._modal ._header {
  background-color: var(--light-gray);
  padding: 10px 20px;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

._modal ._footer {
  background-color: var(--light-gray);
  padding: 10px 20px;
}

._modal ._body {
  padding: 10px 20px;
}

._delete_modal {
  width: 400px;
}

._reset_password_modal,
._small_modal {
  width: 500px;
}

._medium_modal {
  width: 50vw;
}

._full_modal {
  width: 80%;
}

@media (max-width: 768px) {
  ._delete_modal,
  ._reset_password_modal,
  ._small_modal,
  ._medium_modal {
    width: 80%;
  }
}

._modal__container {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  box-shadow: -1px 1px 10px #00000013;
  overflow: hidden;
}

.delete-modal__container ._body {
  min-height: 100px;
}

.delete-modal__container ._body__text {
  line-height: 23px;
}

._footer__button {
  padding: 2px 15px;
  border-radius: 10px;
  transition: 200ms;
  font-size: 13px;
  font-weight: 600;
}

._footer__button._gray {
  border: 1px solid var(--border-gray);
}

._footer__button._gray:hover {
  background-color: var(--opaque-gray);
}

._footer__button._red {
  border: 1px solid var(--main-red);
  background-color: var(--main-red);
  color: #fff;
}

._footer__button._green {
  border: 1px solid var(--main-green);
  background-color: var(--main-green);
  color: #fff;
}

.no-content-full {
  width: 100%;
  height: calc(100vh - 150px);
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.no-content_card {
  width: 300px;
  text-align: center;
}

.no-content_card div:not(._button_container) {
  line-height: 20px;
}

._button_container {
  display: inline-block;
  justify-self: center;
  margin: 10px 0;
}

.user-account-sidenav {
  width: 300px;
  transition: 200ms;
}

.account-sidenav {
  position: relative;
}

.account-sidenav .profile-toggle {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  width: calc(100% - 40px);
  z-index: 1;
}

.account-sidenav .profile-toggle:hover {
  background-color: #0478320d;
}

.account-sidenav .profile-toggle ._top {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.account-sidenav .profile-toggle.open {
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem #00000026;
}

.account-sidenav .profile-toggle.open:hover {
  background-color: #fff;
}

.account-sidenav .profile-toggle ._bottom {
  height: 0;
  overflow: hidden;
}

.account-sidenav .profile-toggle ._bottom a {
  display: block;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 200ms;
}

.account-sidenav .profile-toggle ._bottom a:hover {
  background-color: var(--opaque-green);
}

.account-sidenav .profile-toggle.open ._bottom {
  height: auto;
}

.sidenav-links {
  height: calc(100% - 100px);
  overflow-y: auto;
  margin-top: 70px;
  z-index: -1;
}

.sidenav-links .sidenav-link {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  position: relative;
}

.sidenav-links .sidenav-link.active {
  background-color: #0478320d;
  /* border-left: 5px solid #047832; */
  color: #047832;
}

.sidenav-links .sidenav-link.active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 20%;
  width: 5px;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
  height: 60%;
  background-color: #047832;
}

.sidenav-links .sidenav-link .icon {
  margin-right: 10px;
  width: 20px;
}

.sidenav-links .sidenav-link.active._drop {
  background-color: transparent;
}

._dropdown_content {
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: 200ms;
}

._dropdown_content._open {
  margin: 10px 0;
  height: auto;
}

._dropdown_content .__item {
  padding: 3px 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  position: relative;
  border-radius: 50px;
}

._dropdown_content .__item .__icon {
  width: 30px;
  display: flex;
}

._dropdown_content .__item.active {
  background-color: #0478320d;
  /* border-left: 5px solid #047832; */
  color: #047832;
}

.user-account-content {
  width: calc(100% - 300px);
  margin: 10px;
  height: calc(100vh - 20px);
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 0px 10px #00000014;
}

.user-account__body {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.user-account-sidenav.open {
  left: 0;
  z-index: 10;
}

.user-account-sidenav.open::before {
  content: '';
  display: none;
  position: fixed;
  top: 0;
  left: 300px;
  width: calc(100vw - 300px);
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.user-account-sidenav-close-btn {
  z-index: 11;
}

.user-account-sidenav-close-btn img {
  width: 40px;
}

.user-account-mobile-nav {
  display: none;
  height: 50px;
  padding: 0 20px;
  display: none;
  align-items: center !important;
  background-color: var(--light-green);
}

.user-account-mobile-nav img {
  width: 30px;
  cursor: pointer;
}

@media (max-width: 992px) {
  .user-account-mobile-nav {
    display: flex;
    justify-content: space-between;
  }
  .user-account-sidenav-close-btn {
    top: 10px;
    right: 10px;
  }
  .user-account-sidenav {
    position: absolute;
    background-color: #fff;
    left: -100%;
  }
  .user-account-sidenav.open::before {
    display: block;
  }
  .user-account-content {
    width: 100%;
    box-shadow: none;
    padding-bottom: 50px;
  }
  .user-account-body {
    height: calc(100vh - 50px);
  }
}

.c__container {
  padding: 15px 30px;
}

@media(max-width: 768px) {
  .c__container {
    padding: 10px 5px;
  }
}

.inner__nav {
  background-color: var(--light-gray);
  padding: 15px 30px;
  width: 100%;
  z-index: 5 !important;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

._color-green {
  color: var(--main-green);
}

._border-green {
  border-color: var(--main-green);
}

._border {
  border: 1px solid var(--border-gray);
}

._border-top {
  border-top: 1px solid var(--border-gray);
}

._border-bottom {
  border-bottom: 1px solid var(--border-gray);
}

._border-left {
  border-left: 1px solid var(--border-gray);
}

._border-right {
  border-right: 1px solid var(--border-gray);
}

._d_table {
  display: table;
}

._drag_over {
  box-shadow: 0 0.5rem 1rem #00000026;
  transition: 200ms;
}

.nav_tabs .ant-tabs-nav {
  padding: 0 30px;
}

.nav_tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--main-green);
}

.nav_tabs .ant-tabs-tab:not(.ant-tabs-tab-active):hover {
  color: var(--lime);
}

.nav_tabs .ant-tabs-ink-bar {
  background: var(--main-green);
}

/* Dashboard */
.activity {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.activity__avatar {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activity__avatar_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.activity__avatar_img img {
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.activity__content {
  width: calc(100% - 170px);
}

.activity__content * {
  line-height: 20px;
}

.activity__content ._date {
  font-size: 13px;
  color: var(--main-gray);
}

.activity__action {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business_container {
  width: 100%;
  padding: 20px 10px;
  border-radius: 10px;
  border: 1px solid var(--border-gray);
  display: flex;
  margin-bottom: 15px;
}

.business_container .__list_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.business_container__img {
  width: 100px;
  display: flex;
  justify-content: center;
}

.business_container__action {
  display: flex;
  align-items: center;
}

.business_container__content {
  padding-left: 10px;
}

@media (max-width: 737px) {
  .business_container__content ._title * {
    display: inline-block;
  }
}

.business_container__img ._img_container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.business_container__img ._img_container._listing {
  border-radius: 10px;
}

@media (max-width: 768px) {
  .business_container {
    display: block;
  }
  .business_container__img {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
  }
  .business_container__img ._img_container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
}

.business_container__img ._img_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.business_container__content ._type {
  font-size: 13px;
  color: var(--main-gray);
  line-height: 20px;
}

.reusable__options {
  position: relative;
}

.reusable__options_toggle {
  width: 50px;
  display: flex;
  justify-content: flex-end;
}

.reusable__options_toggle .btn {
  cursor: pointer;
  width: 30px;
}

.reusable__options_container {
  padding: 10px 0;
  min-width: 200px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem #00000026;
  position: absolute;
  top: 35px;
  z-index: 3;
}

.reusable__options_container.left {
  right: 0px;
}

.reusable__options_container.right {
  left: 0px;
}

.reusable__options_item {
  cursor: pointer;
  border-radius: 2px;
  padding: 0 10px;
}

.reusable__options_item:hover {
  background-color: var(--opaque-gray);
}

.reusable__options_item._delete ._text {
  color: var(--main-red);
}

.reusable__options_item ._link_container {
  width: 100%;
  display: flex;
}

.reusable__options_item img {
  width: 18px;
}

.reusable__options_item ._text {
  font-size: 13px;
  width: calc(100% - 20px);
}

.account__business_detail ._head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account__business_detail ._head__title {
  display: flex;
  align-items: center;
}

.account__business_detail ._head__title ._img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;
}

.account__business_detail ._head__title ._img._listing {
  border-radius: 10px;
}

.account__business_detail ._head__title img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.account__business_detail ._head__title h1 {
  margin-right: 10px;
  font-size: 25px;
  font-weight: 700;
}

.account__business_detail ._head__action .dd-toggle {
  margin-right: 10px;
}

.account__business_detail ._content__left {
  width: 60%;
  padding-right: 20px;
}

.account__business_detail ._content__right {
  width: 40%;
  padding-left: 20px;
  border-left: 1px solid var(--border-gray);
}

@media (max-width: 992px) {
  .account__business_detail ._content__left,
  .account__business_detail ._content__right {
    width: 100%;
    padding: 0;
  }
}
._description {
  line-height: 25px;
}

._description * {
  font-family: 'proxima-nova' !important;
  font-size: 14px !important;
}

._description a,
._description a *,
._account_rich_editor ._content a {
  color: var(--main-green) !important;
}

._description a:hover,
._description a *:hover,
._account_rich_editor ._content a:hover {
  text-decoration: underline;
}

._contact_details__item {
  display: flex;
}

._contact_details__item img {
  width: 15px;
  height: 15px;
}

._contact_details__item ._text {
  width: calc(100% - 15px);
  padding-left: 10px;
  line-height: 20px;
  margin-bottom: 10px;
}

._business_hours__item {
  display: flex;
}

._business_hours__item ._day {
  width: 40%;
  line-height: 20px;
  margin-bottom: 10px;
}

._business_hours__item ._time {
  width: 60%;
  line-height: 20px;
  margin-bottom: 10px;
}

.account__business_detail ._gallery,
.account__business_edit_gallery ._gallery {
  position: relative;
}

.account__business_detail ._product {
  line-height: 20px;
}

.owl_carousel__detail_gallery_item {
  width: 100%;
  height: 150px;
  display: block;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid var(--border-gray);
}

.owl_carousel__detail_gallery_item ._info {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #ffffffc4;
  color: #000;
  font-weight: 500;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.owl_carousel__detail_gallery_item .__cancel {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
  background-color: #fff;
  border-radius: 50%;
  width: 25px;
  cursor: pointer;
  padding: 4px;
  transition: 200ms;
}

.owl_carousel__detail_gallery_item .__cancel:hover {
  transform: scale(1.1);
}

.owl_carousel__detail_gallery_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition: 200ms;
}

.owl_carousel__detail_gallery_item img:hover {
  transform: scale(1.1);
}

._rate img {
  width: 17px;
}

._review_item {
  margin-bottom: 20px;
}

._review_item_head {
  display: flex;
  align-items: center;
}

._review_item_head ._img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

._review_item_head ._img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

._review_item_head ._title {
  width: calc(100% - 50px);
  padding-left: 10px;
  line-height: 20px;
}

._review_item_head ._title ._name {
  font-size: 17px;
  font-weight: 600;
}

._review_item_head ._title ._location {
  color: var(--main-gray);
  font-size: 12px;
}

._review_item_body ._review_date {
  color: var(--main-gray);
  font-size: 12px;
  margin-left: 10px;
}

._review_item_body ._review_text {
  line-height: 20px;
  margin: 8px 0 15px 0;
}

._promotion__plans {
  margin: 50px 0;
}

._documents_list ._document {
  position: relative;
}

._documents_list a {
  display: block;
}

._documents_list ._document ._file_icon {
  width: 50px;
}

._documents_list ._delete_document {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: 200ms;
}

._document ._delete_document:hover {
  transform: scale(1.1);
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.__cropper_controls_zoom_range {
  width: 80%;
  height: 3px;
  -webkit-appearance: none;
  border-radius: 5px;
  background-image: linear-gradient(#000000, #000000);
  background-size: 0% 0%;
  background-repeat: no-repeat;
}

.__cropper_controls_zoom_range::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  cursor: ew-resize;
  box-shadow: 0px 2px 10px #0000003a;
}

.__cropper_controls_zoom_range::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  cursor: ew-resize;
  box-shadow: 0px 2px 10px #0000003a;
}

.__cropper_controls_zoom_range::-ms-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  cursor: ew-resize;
  box-shadow: 0px 2px 10px #0000003a;
}


.real-estate .ant-tabs-top > .ant-tabs-nav::before{
  border: 0;
}
.real-estate .ant-tabs-tab{
  width: 60px;
color: #06152B99;
font-size: 14px;
font-weight: 600;
}
.real-estate .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
color: inherit;
}

.real-estate .ant-tabs-ink-bar{
  background-color: #94D60A;
}
.real-estate .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-nav-wrap{
  border-bottom: 2px solid #A2D24366;
}



.ant-drawer-header{
  border-bottom: none !important;
}
.ant-drawer-header-title{
  flex-direction: row-reverse;
}
.ant-drawer-title{
  font-size: 36px;
  font-weight: bold;

}
.ant-drawer .ant-drawer-content {
  padding:3rem;
  overflow:auto;
}

.ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
 padding: 5px;
 border-radius: 5px;
  background: #06152B;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:hover {
  color: #fff;
}

.profile-tabs  .ant-tabs-nav{
  border-bottom: 1px solid #84818a;
}
.profile-tabs  .ant-tabs-nav .ant-tabs-tab{
  /* width: 100px; */
  color: #06152B99;
  font-size: 14px;
  font-weight: 600;
}
.profile-tabs  .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: inherit;
}
.profile-tabs .ant-tabs-ink-bar{
  background-color: #047832;
}